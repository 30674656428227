export const role = {
  SUPPER_ADMIN: 'super_admin',
  ADMIN: 'admin',
  MANAGER: 'truong_nganh',
  ORDER_MANAGER: 'truong_mua_hang',
  ORDER: 'mua_hang',
  SALE_MANAGER: 'truong_kinh_doanh',
  SALE: 'kinh_doanh',
  ACCOUNTANT: 'ke_toan',
  // ACCOUNTANT_MANAGER: 'truong_ke_toan',
}


// truong